<template>
  <div id="member-activity-list">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col align="right" cols="12" md="12" lg="12">
            <router-link
              to="/my-activity/create"
              v-if="tableOptions1.CreateButtonFlag"
            >
              <v-btn
                color="#8950FC"
                elevation="30"
                rounded
                medium
                class="font-size-h6 px-3 mr-3 my-3 white--text"
              >
                &nbsp; Create &nbsp;
              </v-btn>
            </router-link>
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              rounded
              class="font-size-h6 px-3 mr-3 my-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-row wrap v-if="tableData1.length > 0">
          <v-col align="center" cols="12" md="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <br />
            <v-data-table
              class="elevation-1 text-h6"
              v-model="selected1"
              :search="search"
              :item-key="tableOptions1.ItemKey"
              :single-select="tableOptions1.SingleSelectFlag"
              :show-select="tableOptions1.ShowSelectFlag"
              :headers="tableColumns1"
              :items="tableData1"
              :items-per-page="tableOptions1.ItemsPerPage"
              :footer-props="tableOptions1.FooterProps"
            >
              <template v-slot:[`item.ActiveStatusTxt`]="{ item }">
                <v-chip
                  :color="getActiveStatusColor(item.ActiveStatusTxt)"
                  draggable
                  dark
                  >{{ item.ActiveStatusTxt }}</v-chip
                >
              </template>
              <template v-slot:[`item.MemberActivityInvitaionImagePath`]="{ item }">
                <div
                  v-if="
                    item.MemberActivityInvitaionImagePath != '' &&
                    item.MemberActivityInvitaionImagePath != null
                  "
                >
                  <img
                    width="100"
                    height="100"
                    :src="item.MemberActivityInvitaionImagePath"
                  />
                </div>
                <div v-else>NA</div>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-tooltip bottom v-if="item.PreviewButtonFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click="previewData(item, e)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-search
                    </v-icon>
                  </template>
                  <span> Preview </span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.EditButtonFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="warning"
                      @click="editAlert(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-document-edit
                    </v-icon>
                  </template>
                  <span> Edit </span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.DeleteButtonFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="#ff0000"
                      @click="deleteAlert(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> Delete </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="65%">
      <trainers-activity-preview
        v-if="previewRecordPrompt"
        pageTitle="Trainer activity details"
        :previewRecordFlag="previewRecordPrompt"
        :recordData="selectedData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
      ></trainers-activity-preview>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import TrainersActivityPreview from "@/view/pages/erp/trainers-activity/TrainersActivityPreview.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    TrainersActivityPreview
  },
  data() {
    return {
      search: "",
      uploadPercentage: 0,
      LoadingFlag: false,
      OverlayLoadingFlag: false,
      valid: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordData: {},

      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        this.getTableRecords();
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "trainer_activity",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var id = tr.MemberActivityId;
        this.$router.push("/my-activity/edit?id=" + id);
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      //   var result = this.$refs.form.validate();
      var result = true;
      console.log("result=" + result);

      if (result) {
        this.LoadingFlag = true;

        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-activity/lists";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;

            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Date <b> " + tr.MemberActivityDateTxt + "</b>";
        htmlTxt +=
          "<br/>Organization Type <b> " + tr.OrganizationTypeName + "</b>";
        htmlTxt +=
          "<br/>LO Name <b> " + tr.ZoneName + " - " + tr.LomName + "</b>";

        htmlTxt += "<br/>Guest/Faculty Type <b> " + tr.CategorySubName + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-activity/destroy";
        var upload = {
          UserInterface: 1,
          MemberActivity: tr.MemberActivityId,
          Member: tr.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#member-activity-list {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>